<template>
  <Layout>
    <div class="container">
      <h1>Thank you for confirming your email {{ user.email }}!</h1>
      <h3>Please sign in to start using the application.</h3>
      <div class="columns buttons is-centered">
        <div class="column is-one-fifth">
          <router-link to="/login" class="button is-primary is-fullwidth"
            >Log in</router-link
          >
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  name: 'confirmation',
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  props: {},
  data: function() {
    return {
      user: {
        email: ''
      }
    };
  },
  created: function() {
    const url = $formsConfig.core.api.confirmation;
    const data = {
      code: this.$route.params.code
    };
    let self = this;
    axios
      .post(url, data)
      .then(function(response) {
        self.user = response.data;
      })
      .catch(function(error) {
        console.error(error);
      });
  }
};
</script>
